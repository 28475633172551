(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name championships.championship
   * @description
   *
   */
  angular
    .module('neo.home.championships.championship', [
      'ui.router',
      'neo.home.championships.championship.assignTeams',
      'neo.home.championships.championship.draw',
      'neo.home.championships.championship.assignMatches',
      'neo.home.championships.championship.editMatches'
    ]);
}());
